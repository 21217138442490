
import { defineComponent, onMounted, ref, watch } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { TODAY, TOMORROW } from "@/core/data/events";
import { ContentLoader } from 'vue-content-loader';
import { Modal } from "bootstrap";
import moment, { Moment } from "moment";
import { editAgendamento, getMecanicos, getServicosAgendados } from '@/services/AgendaService';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { auto } from "@popperjs/core";
import { config } from "@/views/agenda-virtual/ConfigEvents";
import useAlert from "@/composables/Alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import useEmitter from '@/composables/Emmiter';
import AuthService from "@/services/AuthService";
import { useRoute } from 'vue-router';
import { AgendaServico} from "@/models/AgendaModel";

interface ExtendedAgendaServico extends AgendaServico {
  endWithout?: string;
}

export default defineComponent({
  name: "calendar-app-1",
  components: {
    FullCalendar,
    ContentLoader
  },

  setup() {
    const route = useRoute();
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const isUserActive = ref<any>(false);
    const { showTimeAlert } = useAlert();
    const refCalendar:any = ref(null);
    const eventInfo:any = ref();
    const loading:any = ref(true);
    const emitter = useEmitter();
    const cnpjConcessionaria = ref("");
    const codConcessionaria = ref("");
    const tecnicos:any = ref([]);

    async function getTecnicos(){
      const response = await getMecanicos(codConcessionaria.value);
      tecnicos.value = response;
      while(resources.value.length) {
        resources.value.pop();
      }

      response.forEach((tecnico, index) => {
        const resource = {
          id: `${tecnico.codMecanico}`,
          title: JSON.stringify({
            nome: tecnico.nome,
            imagem: tecnico.imagem,
          }),
          nome: tecnico.nome,
          servico: "",
          num: index + 1,
          eventColor: "none",
          status: "Plano"
        };

        resources.value.push(resource);
        // refCalendar.value.getApi().addResource(resource);
        // refCalendar.value.getApi().addResource(resourceB);
      });
    }

    function clearCalendar(){
      const eventsCalendar = refCalendar.value.getApi().getEvents();
      eventsCalendar.forEach(event => {
        event.remove();
      });
    }


    async function getEvents(currentDate:boolean | Moment = false, showLoading = true){
      if(!cnpjConcessionaria.value){
        return;
      }

      if(refCalendar.value && refCalendar.value.getApi().getEvents().length === 0 && tecnicos.value.length === 0){
        loading.value = true;
      }

      try {
        let dateFilter;
        if(!currentDate){
          dateFilter = moment(refCalendar.value.getApi().getDate());
        } else {
          dateFilter = currentDate;
        }
        
        if(!loading.value && showLoading){
          loading.value = true;
        }
        const response = await getServicosAgendados(codConcessionaria.value, moment(dateFilter).format("YYYY-MM-DD"));
        
        clearCalendar()
        response.forEach((servico : ExtendedAgendaServico) => {
          const eventConfig = config.events[servico.codTipoServico - 1];
          let newEvent;

          if(servico.tempoAdicional){
            const end = moment(servico.dataFinalServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
            servico.endWithout = end.subtract(servico.tempoAdicional, "minutes").format("YYYY-MM-DD HH:mm:ss");
          } else {
            servico.endWithout = servico.dataInicioServico.replace(" ", "T");
          }

          if(servico.codTipoServico < 9){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}`,
              title: eventConfig.title,
              start: servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                aguarda: servico.aguarda == 1 ? true : false,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                cliente: trataNome(servico.cliente),
                consultor: trataNome(servico.consultorAgendado),
                recepcao: servico.dataRecepcao.replace(" ", "T"), 
                entrega: servico.dataEntrega.replace(" ", "T"),
                placa: servico.placa,
                bgColor: eventConfig.extendedProps.bgColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 9){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}`,
              title: eventConfig.title,
              daysOfWeek: [1, 2, 3, 4, 5, 6],
              startTime: moment(servico.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
              endTime: moment(servico.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
              extendedProps: {
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 10 || servico.codTipoServico == 11){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}`,
              title: eventConfig.title,
              start:  moment(servico.dataInicioServico, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
              end: moment(servico.dataFinalServico, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
              extendedProps: {
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          } else if(servico.codTipoServico == 12){
            newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}`,
              title: servico.observacao,
              start: servico.dataInicioServico.replace(" ", "T"),
              end: servico.dataFinalServico.replace(" ", "T"),
              extendedProps: {
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                bgColor: eventConfig.extendedProps.bgColor,
                headerTextColor: eventConfig.extendedProps.headerTextColor,
                allInfo: servico,
              }
            };
          }

          refCalendar.value.getApi().addEvent(newEvent);
        });

        const calendarioDataAtual = moment().isSame(moment(refCalendar.value.getApi().getDate()), 'day');
        if(calendarioDataAtual && !isUserActive.value){
          refCalendar.value.getApi().scrollToTime(moment().subtract(2.25, "hours").format("HH:mm"));
        }
      } catch (e) {
        showTimeAlert('Não foi possível completar a solicitação', "error");
        loading.value = false;
      } finally {
        loading.value = false;
      }
    }

    function calendarFullscreen() {
      if(document.fullscreenElement){
        document.exitFullscreen();
        return
      } 
      let elem:any = document.querySelector("#card-calendar-1");
      if (elem.requestFullscreen) {
          elem.requestFullscreen({ navigationUI: "show" });
      } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen({ navigationUI: "show" });
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen({ navigationUI: "show" });
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen({ navigationUI: "show" });
      }
    };

    function openVisualizarServicoModal(info) {
      if(document.fullscreenElement){
        document.exitFullscreen();
      } 
      eventInfo.value = info.event?.extendedProps?.allInfo;
      const modal = new Modal(document.getElementById("kt_modal_event_info"));
      modal.show();
    };

    const todayDate = moment().startOf("day");
    const YM = todayDate.format("YYYY-MM");

    onMounted(async () => {
      const cod:any = route.params.cod;
      const cnpj:any = route.params.cnpj;
      codConcessionaria.value = cod;
      cnpjConcessionaria.value = cnpj;
      getTecnicos();
      // let elem:any = document.querySelector("#card-calendar");
      // elem.perfectScrollbar('destroy');

      setInterval(() => {
        if(cnpjConcessionaria.value){
          getEvents(false, false);
        }
      }, 90000);

      inactivityTime();
      emitter.emit("getFilters", true);
    });

    function inactivityTime () {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserActive.value = false;
      }
      function resetTimer() {
        isUserActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 60000)
      }
    };

    function trataNome(nome){
      if(!nome || nome == "undefined" || nome == undefined){
        return "";
      }
      
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]}` 

      return nomeTratado;
    }
    const resources:any = ref([]);

    let events:any = ref([]);

    const calendarOptions = ref({
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, resourceTimeGridPlugin ],
      headerToolbar: {
        left: "",
        center: "prev title next",
        right: ""
        // right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      nowIndicator: true,
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Thursday
        
        startTime: '07:00', // a start time (10am in this example)
        endTime: '19:00', // an end time (6pm in this example)
      },
      resourceOrder: 'nome',
      slotMinTime: '07:00:00',
      slotMaxTime: '19:00:00',
      allDaySlot: false,
      locale: "pt-br",
      scrollTime: moment().subtract(2.25, "hours").format("HH:mm"),
      initialDate: TODAY,
      snapDuration: "00:15",

      initialView: 'resourceTimelineDay',
      eventColor: 'blue',
      eventBorderColor: 'blue',
      slotMinWidth: "130",
      contentHeight: "auto",
      eventBackgroundColor: 'blue',
      events: events.value,
      resources: resources.value,
      eventDurationEditable: false,
      resourceAreaWidth: '10%',
      resourceAreaColumns: [
        // {
        //   field: 'num',
        //   headerContent: 'Nº',
        //   width: 10,
        //   group: true,
        // },
        {
          field: 'title',
          headerContent: 'Técnico',
          width: 100,
          cellContent: function (info) {
            let propriedades;
            if(info.fieldValue){
              propriedades = JSON.parse(info.fieldValue)
            } else if(info.groupValue){
              propriedades = JSON.parse(info.groupValue)
            }

            const html = `<div class="calendar-image"><img src="${propriedades.imagem}"><div class="calendar-field">${trataNome(propriedades.nome)}</div></div>`
            return { html };
          },
        },
        // {
        //   field: 'servico',
        //   headerContent: 'Serviço',
        //   width: 25,
        //   group: true,
        // },
        // {
        //   field: 'status',
        //   headerContent: '',
        //   width: 40,
        // },
      ],
      views: {
        resourceTimelineDay: { 
          buttonText: "dia",
        },
        // dayGridMonth: { buttonText: "mês" },
      },
      editable: true,
      dayMaxEvents: true, // allow "more" link when too many events
      slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
      },
      datesSet: (dataInfo) => {
        getEvents(moment(dataInfo.start));
      },
      eventContent: function (info, createElement) {
        const start = moment(info.event.start);
        const end = moment(info.event.end);

        const eventInfo = info.event;

        const propriedades = eventInfo.extendedProps;
        let htmlContent =  "";
        let htmlFooter =  "";
        let htmlWait =  "";
        let htmlDuration =  "";
        
        const duration = moment.duration(end.diff(start)).asMinutes();


        if(propriedades.hasContent){
          htmlContent = `<div class="event-content"><div class="event-content-info"><div class="info-label">${propriedades.showDuration && duration > 15 ? 'Recepção' : 'R'}:</div><div class="info-date ${duration <= 15 ? 'd-none' : 'd-none'}">${moment(propriedades.recepcao).format("DD/MM")}</div><div class"info-time">${moment(propriedades.recepcao).format(" HH:mm")}</div></div><div class="event-content-info"><div class="info-label">${propriedades.showDuration && duration > 15 ? 'Entrega' : 'E'}:</div><div class="info-date ${duration <= 15 ? 'd-none' : 'd-none'}">${moment(propriedades.entrega).format("DD/MM")}</div><div class="info-time">${moment(propriedades.entrega).format("HH:mm")}</div></div></div>`;
          htmlWait =  `<div class="event-wait">${propriedades.aguarda ? '<div class="wait-indicator"></div>' : ''}</div>`
        }

        if(propriedades.hasFooter){
          htmlFooter = `<div class="event-footer ${duration <= 15 ? 'flex-column' : ''}"><div class="text-overflow ${duration <= 15 ? 'd-none' : ''}"><svg class="d-none" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.9375 4.5C5.18027 4.5 6.1875 3.49277 6.1875 2.25C6.1875 1.00723 5.18027 0 3.9375 0C2.69473 0 1.6875 1.00723 1.6875 2.25C1.6875 3.49277 2.69473 4.5 3.9375 4.5ZM5.5125 5.0625H5.21895C4.82871 5.2418 4.39453 5.34375 3.9375 5.34375C3.48047 5.34375 3.04805 5.2418 2.65606 5.0625H2.3625C1.0582 5.0625 0 6.1207 0 7.425V8.15625C0 8.62207 0.37793 9 0.84375 9H7.03125C7.49707 9 7.875 8.62207 7.875 8.15625V7.425C7.875 6.1207 6.8168 5.0625 5.5125 5.0625Z" fill="#464E5F"/></svg>${propriedades.consultor}</div><div class="text-overflow"><svg class="d-none" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="12.0909" height="8" rx="1.5" stroke="#464E5F"/><path d="M0 2C0 0.89543 0.895431 0 2 0H11.0909C12.1955 0 13.0909 0.895431 13.0909 2V4.5H0V2Z" fill="#464E5F"/></svg>${propriedades.placa}</div></div>`;
        }

        if(propriedades.showDuration && duration > 15){
          htmlDuration =  `- ${duration} min`;
        }
        
        const html = `<div class="event" style="${!propriedades.hasContent ? 'background-color:' + propriedades.bgColor : 'background-color: white'}"><div class="event-header" style="color: ${propriedades.headerTextColor}; background-color: ${propriedades.bgColor}"><span class="event-title ${duration <= 15 ? 'event-title-min' : ''} ${propriedades.allInfo.codTipoServico > 8 ? 'text-truncate' : '' }" style="color: ${propriedades.headerTextColor}">${eventInfo.title} ${htmlDuration}</span>${htmlWait}</div>${htmlContent}${htmlFooter}</div>`;
        return { html };
      }
    });
    return {
      tecnicos,
      calendarOptions,
      events,
      refCalendar,
      resources,
      getEvents,
      eventInfo,
      calendarFullscreen,
      loading,
      cnpjConcessionaria,
      codNivel,
      codConcessionaria
    };
  },
});
